import React, { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

const Copyright = (): ReactElement => (
  <Typography variant="body2">
    {'Copyright © '}
    <Link color="inherit" href="https://www.novozymes.com/">
      Novozymes
    </Link>
    {' '}
    {new Date().getFullYear()}
    .
  </Typography>
);

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    color: theme.palette.text.secondary,
    borderTop: '3px solid #c5da00',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default (): ReactElement => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        <Copyright />
        <Typography variant="body2">
          Contact us | Legal | Privacy
        </Typography>
      </Container>
    </footer>
  );
};
