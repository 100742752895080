/* eslint-disable max-len */

export interface App {
  id: string;
  name: string;
  description: string;
  url: string;
  icon?: string;
}
interface AppDirectory {
  [key: string]: AppDirectoryEntry;
}
export interface AppDirectoryEntry {
  featured?: App[]; other?: App[];
}

const community = {
  id: 'community',
  name: 'Community',
  description: 'A great app for doing great things with great products from Novozymes',
  url: 'https://uat-novozymes.cs74.force.com/TSNow/',
};

const academy = {
  id: 'academy',
  name: 'Academy',
  description: 'A great app for doing great things with great products from Novozymes',
  url: `${process.env.REACT_APP_SALESFORCE_COMMUNITY_URL}/services/oauth2/authorize?client_id=${process.env.REACT_APP_SALESFORCE_CLIENT_ID_ACADEMY}&response_type=code&scope=openid%20address%20profile%20web%20phone%20id%20email&redirect_uri=${encodeURIComponent(process.env.REACT_APP_ACADEMY_URL ?? 'undefined')}%2Fmanage%2Fv1%2Fopenidconnect%2Fcode`,
};

const market = {
  id: 'market',
  name: 'Novozymes Market',
  description: 'A great app for doing great things with great products from Novozymes',
  url: 'https://market.novozymes.com/',
};

const apps: AppDirectory = {
  Beverage: {
    featured: [market, academy, community],
    other: [
      {
        id: 'troubleshooter',
        name: 'Brewing Troubleshooter SSO POC',
        description: 'A great app for doing great things with great products from Novozymes',
        url: 'https://brewing-sso-poc.test.novozymes.cloud',
        icon: '/icons/beverages.png',
      },
      {
        id: 'biofuel',
        name: 'Biofuel SSO POC',
        description: 'A great app for doing great things with great products from Novozymes',
        url: 'http://biofuel-sso-poc.test.novozymes.cloud',
        icon: '/icons/beverages.png',
      },
    ],
  },
  Baking: {
    featured: [market, academy, community],
    other: [
      {
        id: 'smartBake',
        name: 'Smart bake',
        description: 'A great app for doing great things with great products from Novozymes',
        url: 'https://smartbake-sso-poc.test.novozymes.cloud',
        icon: '/icons/baking.png',
      },
    ],
  },
  HHC: {
    featured: [market, academy, community],
    other: [
      {
        id: 'washLab',
        name: 'Wash lab',
        description: 'A great app for doing great things with great products from Novozymes',
        url: 'https://washlab-sso-poc.test.novozymes.cloud',
        icon: '/icons/household_care.png',
      },
      {
        id: 'dishLab',
        name: 'Dish lab',
        description: 'A great app for doing great things with great products from Novozymes',
        url: 'https://dishlab-sso-poc.test.novozymes.cloud',
        icon: '/icons/household_care.png',
      },
      {
        id: 'troubleshooter',
        name: 'Brewing Troubleshooter SSO POC',
        description: 'A great app for doing great things with great products from Novozymes',
        url: 'https://brewing-sso-poc.test.novozymes.cloud',
        icon: '/icons/beverages.png',
      },
      {
        id: 'biofuel',
        name: 'Biofuel SSO POC',
        description: 'A great app for doing great things with great products from Novozymes',
        url: 'http://biofuel-sso-poc.test.novozymes.cloud',
        icon: '/icons/beverages.png',
      },
      {
        id: 'wastewater',
        name: 'Wastewater',
        description: 'A great app for doing great things with great products from Novozymes',
        url: 'https://wastewater.novozymes.cloud',
        icon: '/icons/wastewater.png',
      },
    ],
  },
  wastewater: {
    featured: [market, academy, community],
    other: [
      {
        id: 'wastewater',
        name: 'Wastewater',
        description: 'A great app for doing great things with great products from Novozymes',
        url: 'https://wastewater.novozymes.cloud',
        icon: '/icons/wastewater.png',
      },
    ],
  },
};

export default (industry: string): AppDirectoryEntry => apps[industry] || {};

export function getAppsForIndustries(industries: string[]): AppDirectoryEntry {
  console.log(industries);
  const applications = {
    featured: [market, academy, community],
    other: industries.flatMap((industry: string) => apps[industry]?.other),
  };
  console.log(applications);
  return applications as AppDirectoryEntry;
}
