import React, { ReactElement, ReactNode } from 'react';
import {
  makeStyles,
  Container,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
}));

type BodyProps = {
  children: ReactNode;
}

const Body = ({ children, ...props }: BodyProps): ReactElement => {
  const classes = useStyles();

  return (
    <Container fixed {...props} className={classes.container}>
      {children}
    </Container>
  );
};

export default Body;
