import React, {
  ReactElement, ReactNode, useEffect, useState,
} from 'react';
import {
  Button, Box, makeStyles, Typography, Container, Menu, MenuItem,
} from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SignOutButton from 'auth/components/SignOutButton';
import getUser from 'utils/getUser';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: 'url("/gras_background.jpg")',
    backgroundSize: 'cover',
    width: '100%',
    borderBottom: '5px solid #cdd0cd',
  },
  logo: {
    width: '94px',
  },
  pointer: {
    cursor: 'pointer',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(246, 247, 245, 0.85)',
  },
  hero: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

  },
  heroLogo: {
    color: '#fff',
  },
}));

interface HeaderProps extends RouteComponentProps {
  children?: ReactNode;
}

const Header = ({ history }: HeaderProps): ReactElement => {
  const classes = useStyles();

  const [user, setUser] = useState<{ [key: string]: any }>({});

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getUser().then((userInfo) => {
      console.log(userInfo);
      setUser(userInfo.payload);
    });
  }, []);

  return (
    <Box className={classes.container}>

      <Box className={classes.topBar}>
        <Box m={2} className={classes.pointer} onClick={(): void => history.push('/')}>
          <img id="logo" className={classes.logo} src="/logo.png" alt="Novozymes Logo" />
        </Box>
        <Box m={2} className={classes.pointer} onClick={(): void => history.push('/')}>
          <Typography variant="body1">
            Welcome Back,
            {' '}
            { user.name}
            {' '}
          </Typography>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            Debug
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Typography variant="body1">
              { user['custom:company'] }
            </Typography>

            <Typography variant="body1">
              { user.profile }
            </Typography>
          </Menu>
          {' '}

          <SignOutButton />
        </Box>
      </Box>
      <Container>
        <Box mt={7} mb={16} className={classes.hero}>
          <Box className={classes.heroLogo}>
            <Typography variant="h1">
              MyNovozymes
            </Typography>
            <Typography variant="subtitle1">
              All Novozymes digital services in one place
            </Typography>
          </Box>
          <Button variant="contained">Give feedback</Button>
        </Box>

      </Container>
    </Box>
  );
};

export default withRouter(Header);
