import React, { ReactElement } from 'react';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import { Authenticator } from '@novozymes/auth';
import TagManager from 'react-gtm-module';
import './i18n';
import authConfig from 'authConfig';
import Page from 'layout/Page';
import Welcome from 'pages/Welcome';
import Logout from 'pages/Logout';
import { Amplify } from '@aws-amplify/core';

TagManager.initialize({
  gtmId: 'GTM-K83D567',
});

function App(): ReactElement {
  console.log(authConfig);
  Amplify.configure(authConfig);
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Authenticator amplifyConfig={authConfig}>
            <Page>
              <Welcome />
            </Page>
          </Authenticator>
        </Route>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route path="/public">
          <p>Public page without authentication</p>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
