import { createMuiTheme } from '@material-ui/core/styles';

const noTheme = createMuiTheme({
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: '56px',
      lineHeight: 1.14,
      color: '#fff',
    },
    h2: {
      opacity: 0.8,
      fontSize: '32px',
      lineHeight: 1.25,
      color: '#000',
    },
    subtitle1: {
      fontWeight: 600,
    },
  },
});

export default noTheme;
