import React, { ReactElement, useEffect, useState } from 'react';
import {
  Box, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { App, AppDirectoryEntry } from 'utils/getAppsForIndustry';
import getUserApps from 'utils/getUserApps';
import getUser from 'utils/getUser';
import AppCard from 'components/AppCard';
import TopAppCard from 'components/TopAppCard';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import getAccessToken from 'utils/getAccessToken';
import getIdToken from 'utils/getIdToken';

const useStyles = makeStyles((theme) => ({
  topRowApps: {
    marginTop: theme.spacing(-16),
  },
}));

const IndustryApps = (): ReactElement => {
  const [apps, setApps] = useState<AppDirectoryEntry>({});
  const [user, setUser] = useState<{ [key: string]: any }>({});
  const { t } = useTranslation('industryAppsPage');

  useEffect(() => {
    getUserApps().then((userApps) => {
      setApps(userApps);
    });
  }, []);

  useEffect(() => {
    getUser().then((userInfo) => {
      setUser(userInfo);
    });
  }, []);

  const classes = useStyles();

  const { featured, other } = apps;

  return (
    <>
      { user
        ? (
          <Box mb={5}>
            Welcome Back
            {' '}
          </Box>
        )
        : (
          <Box mb={5}>
            It seems like your account is waiting for approval
          </Box>
        )}

      {featured && featured.length && (
        <Box mb={5} className={classes.topRowApps}>
          <Grid container spacing={2} alignItems="stretch">
            {featured.map((appData: App) => <Grid key={appData.id} item md={3}><TopAppCard {...appData} /></Grid>)}
          </Grid>
        </Box>
      )}
      {other && other.length && (
        <>
          <Typography variant="h2">
            {t('appsHeading')}
          </Typography>
          <Box my={3}>
            <Grid container spacing={2} alignItems="stretch">
              {other.map((appData: App) => <Grid key={appData.id} item md={3}><AppCard {...appData} /></Grid>)}
            </Grid>
          </Box>
        </>
      )}

    </>
  );
};

export default IndustryApps;
