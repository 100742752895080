import React, { useEffect, useState, ReactElement } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Button } from '@material-ui/core';

const checkIsSignedIn = async (): Promise<boolean> => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (e) {
    return false;
  }
};

export default (): ReactElement | null => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  const signOut = async (): Promise<void> => {
    await Auth.signOut();
    setIsSignedIn(false);
  };

  useEffect(() => {
    checkIsSignedIn().then((result) => {
      setIsSignedIn(result);
    });
  }, []);

  return isSignedIn ? (
    <Button
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={signOut}
    >
      <img style={{ maxHeight: 18, marginRight: 5 }} alt="User icon" src="/icons/user.png" />
      Sign Out
    </Button>
  ) : null;
};
