import React, { ReactElement } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  }
}))

export default ({ children }: { children?: ReactElement }) => {
  const classes = useStyles()
  return (
    <Box className={classes.wrapper}  >
      <Header />
      <Body>
        {children}
      </Body>
      <Footer />
    </Box>
  )
}
