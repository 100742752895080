import React, { ReactElement } from 'react';
import {
  withRouter, RouteComponentProps,
} from 'react-router-dom';

const Logout = ({ history }: RouteComponentProps): ReactElement => {
  history.push('/')

  return (
    <>
      You´re being logged out...
    </>
  );
};

export default withRouter(Logout);
