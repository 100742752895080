import React, { ReactElement } from 'react';
import {
  Button, Card, CardActions, CardContent, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  content: {
    padding: 32,
  },
  actions: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 32,
    paddingTop: 0,
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
  },

});

const TopAppCard = (props: { name: string; description: string; url: string }): ReactElement => {
  const classes = useStyles();
  const {
    name, description, url,
  } = props;

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography className={classes.title} gutterBottom>
          {name}
        </Typography>
        <Typography variant="body1" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button variant="contained" target="blank" href={url}>Visit</Button>
      </CardActions>
    </Card>
  );
};

export default TopAppCard;
