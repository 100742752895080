import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import languages from 'languages';

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: languages,
  fallbackLng: 'en',
  debug: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'),
  ns: ['global'],
  defaultNS: 'global',
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

export default i18n;
